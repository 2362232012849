import React, { Component } from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';

import Layout from './components/Layout/Layout';
import Home from './containers/Home/Home';
import Contact from './containers/Contact/Contact';
import Purchase from './containers/Purchase/Purchase';
import ImageSliderWrapper from './containers/ImageSlider/ImageSlider';
import ThankYou from './containers/ThankYou/ThankYou';




class App extends Component {
  render () {
    return (
      <BrowserRouter>
        <div>
          <Layout>      
          <Routes>
          <Route path="/product/:source" element={<ImageSliderWrapper />} />
              <Route path="/purchase" element={<Purchase />} />
              <Route path="/thankyou" element={<ThankYou />} />
              <Route path="/contact" element={<Contact />} />
              {/* <Route path="/chausette-intelligente" element={<ImageSlider />} /> */}
              {/* The '*' path is used to catch all routes that haven't matched those defined above. */}
              <Route path="*" element={<Home />} />
            </Routes>
            
          </Layout>     
        </div>  
      </BrowserRouter>
      
      
    );
  }
}

export default App;
