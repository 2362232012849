import React from 'react';
import classes from './Banner.module.css';


const Banner = () => {
  return (
    <div className={classes.Banner}>
      <p>🌟💥 Soldes Flash! 🎉 30% de Réduction + Livraison Gratuite</p>
    </div>
  );
};
export default Banner;