import React from 'react';
import styles from './CustomerReviews.module.css';

const CustomerReviews = ({ reviews }) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Ce qu'en pensent nos Clients</h2>
      <div className={styles.reviewsGrid}>
        {reviews.map((review, index) => (
          <div key={index} className={styles.reviewCard}>
            <h3 className={styles.reviewerName}>{review.name}</h3>
            <p className={styles.reviewText}>{review.text}</p>
            <div className={styles.rating}>
              {'⭐'.repeat(review.rating)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerReviews;
