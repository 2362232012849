import React from 'react';
import classes from './VideoPlayer.module.css';

const VideoPlayer = ({ src }) => {
  return (
    <div className={classes.VideoContainer}>
      <video className={classes.ResponsiveVideo} controls>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;