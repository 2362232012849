import React from 'react';
import styles from './Panel.module.css';
import sleepIcon from '../../assets/images/icons/sleep.PNG';
import aromaIcon from '../../assets/images/icons/aroma.PNG';
import relaxingIcon from '../../assets/images/icons/relaxing.PNG';

const Panel = ({ imagesUrl, data }) => {
  return (
    <div className={styles.Panel}>
      <div className={styles.Content}>
        <ul className={styles.List}>
          {data.map((item, index) => (
            <li key={index} className={styles.Item}>
              <img className={styles.Image} src={`${imagesUrl}/${item.icon}`} alt={item.title} />
              <div>
                <p className={styles.ItemTitle}>{item.title}</p>
                <p className={styles.ItemText}>{item.text}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Panel;
