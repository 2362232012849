import React from 'react';

import classes from './Rating.module.css';



  


const Rating = ({ rating, totalReviews }) => {
    const fullStars = Math.floor(rating);
    const halfStars = (rating - fullStars) >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    return (
        <div className={classes.ratingWrapper}>
            {[...Array(fullStars)].map((_, i) => (
                <svg key={i} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className={classes.star + ' ' + classes.full}>
                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" fill="yellow" />
                </svg>
            ))}
            {halfStars ? (
                <div className={classes.star + ' ' + classes.half}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                        <defs>
                            <linearGradient id="halfGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="80%" style={{ stopColor: "#FFAA33" }} />
                                <stop offset="20%" style={{ stopColor: "#e1e1e1" }} />
                            </linearGradient>
                        </defs>
                        <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" fill="url(#halfGradient)" />
                    </svg>
                </div>
            ) : null}
            {[...Array(emptyStars)].map((_, i) => (
                <svg key={i + fullStars + halfStars} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className={classes.star}>
                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" fill="#e1e1e1" />
                </svg>
            ))}
            <span className={classes.ratingText}>{rating.toFixed(1)} ({totalReviews})</span>
        </div>
    );
}


export default Rating;