import React, {Component} from 'react';
import picture from '../../assets/images/couverture/pic21.jpg';
import classes from './Home.module.css';
import thirdBoxPic1 from '../../assets/images/pic3.PNG';
import thirdBoxPic2 from '../../assets/images/pic14.jpg';
import FourthBoxPic1 from '../../assets/images/pic10.PNG';
// import FourthBoxPic2 from '../../assets/images/pic9.PNG';
// import FourthBoxPic2 from '../../assets/images/pic8.PNG';
import FourthBoxPic2 from '../../assets/images/pic7.PNG';
// import snipHeart from '../../assets/images/icons/snipHeart.PNG';
// import snipAlarm from '../../assets/images/icons/snipAlarm.PNG';
// import snipSock from '../../assets/images/icons/snipSock.PNG';
import { NavLink } from 'react-router-dom';
import { Player } from 'video-react';
// import ReactPlayer from 'react-player';
import PlayerControlExample from './PlayerControlExample';
import Video1 from '../../assets/images/videos/idVid.mp4';

class home extends Component {
   
    
  componentDidMount () {
    window.scrollTo(0, 0);
  }
    render() {
        return (
            <div className={classes.Container}>
                
                <div className={classes.CouvertureBox}>
                    <img
                    className={classes.Couverture} 
                    src={picture} alt="couverturePic" />
                    {/* <h2 className={classes.TitreCouverture}>Nouveauté et qualité</h2>
                    <p className={classes.TextCouverture}>Profitez de vos achats</p> */}
                    {/* <NavLink
                    to="/chausette-intelligente">
                    <button className={classes.Btn}>Commander maintenant</button>
                    </NavLink> */}
                    
                    
                </div>
{/*                  
                 <ReactPlayer
       src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
     /> */}

    {/* <PlayerControlExample/> */}
    {/* poster="/assets/poster.png" */}
    
  
                 <div className={classes.FirstBox}>
                         <h2 className={classes.FirstBoxTitle}>Notre mission</h2>
                        <h2 className={classes.FirstBoxText}>Nous faisons l’impossible<br/> pour vous proposer les meilleurs produits<br/> avec la meilleure qualité</h2>
        
                </div>
                
                <div className={classes.SecondBox}>
                    <h2 className={classes.SecondBoxText}>Nous vous accompagnons <br/>et veillons à ce que vous soyez satisfaits <br/>de vos achats </h2>
                </div>

                {/* <div className={classes.ThirdBox}>
                    <div className={classes.ThirdBoxLeft}>
                        <img className={classes.ThirdBoxPicLeft} 
                        src={thirdBoxPic2} alt="thirdBoxPic2" />

                        <h2 className={classes.ThirdBoxTitleLeft}>
                            Quand ça importe vraiment
                        </h2>

                        <p className={classes.ThirdBoxTextLeft}>
                            Si les mesures de votre bébé quittent la zone ‘’Sure’’, la chaussette intelligente vous préviendra sur le champ, afin que vous contrôliez votre bébé
                        </p>
                    </div> 
                    <div className={classes.ThirdBoxRight}>
                        <img className={classes.ThirdBoxPicRight} 
                        src={thirdBoxPic1} alt="thirdBoxPic1" />
                        <h2 className={classes.ThirdBoxTitleRight}>
                            Surveiller ce Qui Importe
                        </h2>
                        <p className={classes.ThirdBoxTextRight}>
                        La chaussette intelligente est la première chaussette qui mesure les battements de cœur et la saturation en oxygène de votre bébé
                        </p>
                    </div>

                </div> */}
                <div className={classes.SixthBox}>
                    <h2 className={classes.SixthBoxText}>Plusieurs personnes <br/>nous font confiance et nous disent <br/> que nos produits <br/>ont Changé leurs vies </h2>
                </div>

                {/* <div className={classes.FifthBox}>
                    <div className={classes.FifthBoxElement}>
                    <img className={classes.SnipHeart} 
                        src={snipHeart} alt="heart" />
                        <h2>Controle oxygènne et battement de coeur</h2>
                    </div>
                    <div className={classes.FifthBoxElement}>
                    <img className={classes.SnipHeart} 
                        src={snipAlarm} alt="Alarm" />
                        <h2>Alarame quand votre bébé a besoin de vous</h2>
                    </div>
                    <div className={classes.FifthBoxElement}>
                    <img className={classes.SnipSock} 
                        src={snipSock} alt="Sock" />
                        <h2>Bébés entre 0 et 18 mois</h2>
                    </div>
                </div> */}
               

            </div>
            
            );
    }
}

export default home;