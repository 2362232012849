import React, { Component } from 'react';
import styles from './ThankYou.module.css';
import { useNavigate } from 'react-router-dom';
import axios from '../../axios-clicks';

class ThankYou extends Component {
    constructor(props){
        super(props);
        this.hasTrackedVisit = false;
        
        
      }
      componentDidMount () {
        
        if (!this.hasTrackedVisit) {
          this.trackVisit();
          this.hasTrackedVisit = true;
            }
      }
      trackVisit() {
        const source = "thankyou";
        const formData = new FormData();
        formData.append('event', source);
        axios.post("click", formData)
        .then(response => {
          // Handle the response if needed
          console.log('Visit tracked with parameter:', source, response.data);
      })
      .catch(error => {
          // Handle any errors here
          console.error('Error tracking visit', error);
      });
}
    navigateToHome = () => {
        this.props.navigate('/');
    }

    render() {
        return (
            <div className={styles.thankYouContainer}>
                <h1>Merci pour votre commande!</h1>
                <p>Notre équipe va vous contacter dans les plus brefs délais.</p>
                <button onClick={this.navigateToHome}>Go to Home Page</button>
            </div>
        );
    }
}
const ThankYouWrapper = () => {

    const navigate = useNavigate();

    return <ThankYou navigate={navigate} />;
};
export default ThankYouWrapper;
