import React, { Component } from 'react';
import classes from './ImageSlider.module.css'
import Card from '../../components/Card/Card';
import Rating from '../../components/UI/Rating/Rating';
import Aux from '../../hoc/Auxil';
import axios from '../../axios-clicks';
import { NavLink } from 'react-router-dom';
import ProductInfo from '../ProductInfo/ProductInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faTruck, faWallet, faQuestion, faTruckFast } from '@fortawesome/free-solid-svg-icons'
import Panel from '../Panel/Panel';
import { useParams, useNavigate } from 'react-router-dom';
import VideoPlayer from '../../components/Video/VideoPlayer';

import FourthBoxPic1 from '../../assets/images/pic10.PNG';
import FourthBoxPic2 from '../../assets/images/pic7.PNG';

import snipLivraison from '../../assets/images/icons/sleep.PNG';
import snipPromotion from '../../assets/images/icons/aroma.PNG';
import snipPaiement from '../../assets/images/icons/relaxing.PNG';

import ThirdBoxPic from '../../assets/images/pic15.jpg';
import CustomerReviews from '../CustomerReviews/CustomerReviews';


// class component
class ImageSlider extends Component {

  constructor(props) {
    super(props);
    this.hasTrackedVisit = false;

    this.state = {
      country: null,
      countryCode: null,
      productName: null,
      productPartnerCode: null,
      productPartnerName: null,
      productDetails: null,
      error: null,
      current_index: 0,
      price: null,
      quantity: 1,
      total: null,
      currency: null
      // properties: data.properties,
      // property: data.properties[0],
      // number_of_clicks: 0
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.fetchProductDetails();
    if (!this.hasTrackedVisit) {
      this.trackVisit();
      this.hasTrackedVisit = true;
    }
  }
  componentDidUpdate(prevProps) {
    // Only re-fetch if source has changed
    if (prevProps.source !== this.props.source) {
      this.fetchProductDetails();
    }
  }

  fetchProductDetails = () => {
    const source = this.props.source;
    this.setState({ productName: source });
    fetch(`/products/${source}/details.json`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(details => {
        this.setState({
          productDetails: details,
          country: details.country,
          countryCode: details.countryCode,
          productPartnerCode: details.productPartnerCode,
          productPartnerName: details.productPartnerName,
          price: details.price,
          total: details.price,
          currency: details.currency
        });
        console.log(details);
      })
      .catch(error => {
        console.error('Error loading product details:', error);
        this.setState({ error });
      });
  }
  trackVisit() {
    const source = this.props.source;
    const formData = new FormData();
    formData.append('event', source);
    axios.post("click", formData)
      .then(response => {
        // Handle the response if needed
        console.log('Visit tracked with parameter:', source, response.data);
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error tracking visit', error);
      });
  }

  nextProperty = () => {
    let newIndex = this.state.current_index + 1;
    if (newIndex >= this.state.productDetails.images.length) {
      newIndex = 0;
    }
    this.setState({
      current_index: newIndex
    })
  }

  prevProperty = () => {
    let newIndex = this.state.current_index - 1;
    if (newIndex < 0) {
      newIndex = newIndex + this.state.productDetails.images.length;
    }
    this.setState({
      current_index: newIndex
    })
  }
  incrementQuantity = () => {
    this.setState((prevState) => ({
      quantity: prevState.quantity < 5 ? prevState.quantity + 1 : 5,
    }));
  };

  decrementQuantity = () => {
    this.setState((prevState) => ({
      quantity: prevState.quantity > 1 ? prevState.quantity - 1 : 1,
    }));
  };
  navigateToPurchase = () => {
    const { productDetails, country, countryCode, productPartnerCode, productPartnerName, productName, price, quantity, currency } = this.state;
   
    const total = price * quantity;
  
    const params = {
      country: country,
      countryCode: countryCode,
      productPartnerCode: productPartnerCode,
      productPartnerName: productPartnerName,
      product: productName,
      price: price,
      quantity: quantity,
      total: total, 
      currency: currency
    };
  
    this.props.navigate('/purchase', { state: params });
  }
  
  render() {
    // const {properties, property} = this.state;
    const { productName, productDetails, error, current_index } = this.state;

    if (error) {
      return <div>Error loading product details.</div>;
    }

    if (!productDetails) {
      return <div>Loading...</div>;
    }



    return (
      <Aux>
        <div className={classes.Container}>
          <div className={classes.Burger}>


            <div className={classes.Product}>

              <div className={classes.Front_Card}>
                {/* <img src={`/products/${productName}/images/${productDetails.images[current_index]}`} alt={`Image ${current_index}`} /> */}
                <img src={`/products/miniprojector/images/${productDetails.images[current_index]}`} alt={`Image ${current_index}`} />
              </div>
              <button
                className={classes.ButtonLeft}
                onClick={() => this.prevProperty()}
              // disabled={property.index === 0}
              >&#10094;</button>
              < button
                className={classes.ButtonRight}
                onClick={() => this.nextProperty()}
              // disabled={property.index === data.properties.length-1}
              >&#10095;</button>
              <div className={classes.Slider}>


                <div className={classes.cards_slider}>
                  <div className={classes.cards_slider_wrapper} style={{
                    'transform': `translateX(-${current_index * (100 / productDetails.images.length)}%)`
                  }}>

                    {
                      // properties.map(property => <Card key={property._id} property={property} />)
                      // productDetails.images.map((image, index) => <Card key={index} image={`/products/${productName}/images/${image}`} index={index} />)
                      productDetails.images.map((image, index) => <Card key={index} image={`/products/miniprojector/images/${image}`} index={index} />)
                    }

                  </div>

                </div>
              </div>

            </div>

            <div className={classes.Pricing}>
              <h2 className={classes.Name}>
                {productDetails.name}
              </h2>
              <div>
                <Rating rating={4.8} totalReviews={124} />
              </div>
              <h2 className={classes.ExPrice}>

                {`${productDetails.previous_price} ${productDetails.currency}`}
              </h2>
              <h2 className={classes.Price}>
                {`${productDetails.price} ${productDetails.currency}`}
              </h2>
              <div className={classes.LivraisonContainer}>
                <FontAwesomeIcon icon={faTruck} className={classes.Icon} />
                <p className={classes.Livraison}>
                  Livraison gratuite
                </p>
              </div>
              <div className={classes.LivraisonContainer}>
                <FontAwesomeIcon icon={faWallet} className={classes.Icon} />
                <p className={classes.Livraison}>
                  Paiement à la livraison
                </p>
              </div>
              <div className={classes.LivraisonContainer}>
                <FontAwesomeIcon icon={faTruckFast} className={classes.Icon} />
                <p className={classes.Livraison}>
                  Livraison rapide: 1-2 jours
                </p>
              </div>

              <div className={classes.purchaseContainer}>
                <div className={classes.quantitySelector}>
                  <button className={classes.quantityButton} onClick={this.decrementQuantity}>-</button>
                  <span className={classes.quantitySpan}>{this.state.quantity}</span>
                  <button className={classes.quantityButton} onClick={this.incrementQuantity}>+</button>
                </div>
                <button className={classes.btn} onClick={this.navigateToPurchase}>
                  Commander
                </button>
              </div>
              <p className={classes.Text1}>
                {productDetails.description}</p>

            </div>

          </div>

          {/* <Panel imagesUrl = {`/products/${productName}/images`} data={productDetails.panel}/> */}
          <Panel imagesUrl={`/products/miniprojector/images`} data={productDetails.panel} />
          <ProductInfo title={productDetails.title1} texts={productDetails.texts1} />

          <div className={classes.videoContainer}>
            <h3>{productDetails.video_title}</h3>
            <div>
              {/* <VideoPlayer src={`/products/${productName}/videos/${productDetails.video}`} /> */}
              <VideoPlayer src={`/products/miniprojector/videos/${productDetails.video}`} />
            </div>

          </div>

          {/* <Panel imagesUrl = {`/products/${productName}/images`} data={productDetails.panel2}/> */}
          <Panel imagesUrl={`/products/miniprojector/images`} data={productDetails.panel2} />
        </div>
        <CustomerReviews reviews={productDetails.reviews} />;
      </Aux>
    );
  }
}

const ImageSliderWrapper = () => {
  const { source } = useParams();
  const navigate = useNavigate();
  return <ImageSlider navigate={navigate} source={source} />;
};

export default ImageSliderWrapper;