import React, {Component} from 'react';

import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import classes from './Purchase.module.css';
import axios from '../../axios-clicks';
import Input from '../../components/UI/Input/Input';
import { useLocation, useNavigate  } from 'react-router-dom';
import Modal from '../../components/UI/Modal/Modal';


// import { RuleTester } from 'eslint';

class Purchase extends Component {
    state = {
        orderForm: {
            customerFullName: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Nom & Prénom'
                },
                value: '',
                validation: {
                    required:true
                },
                valid:false,
                touched: false
            },
            country: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Pays'
                },
                value: "",
                validation: {
                    required:true
                },
                valid:true,
                touched: true
            },
            city: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Ville'
                },
                value: '',
                validation: {
                    required:true
                },
                valid:false,
                touched: false
            },
            customerPhoneNumber: {
                elementType: 'input',
                elementConfig: {
                    type: 'tel',
                    placeholder: 'Téléphone'
                },
                value: '',
                validation: {
                    required:true
                },
                valid:false,
                touched: false
            }
        },
        product: null,
        price : null,
        quantity: null,
        total: null,
        currency: null,
        formIsValid: false,
        loading: false,
        errors: {
            
            networkError: false,
            sendingOrderError: false
            
          }
    }
    constructor(props){
      super(props);
      this.hasTrackedVisit = false;
      
      
    }
    
    
    componentDidMount() {
      if (!this.hasTrackedVisit) {
        this.trackVisit();
        this.hasTrackedVisit = true;
          }
        const { country, countryCode, productPartnerCode, productPartnerName, product, price, quantity, total, currency} = this.props.location.state || {};
        
        this.setState(prevState => ({
          ...prevState,
          orderForm: {
              ...prevState.orderForm,
              country: {
                  ...prevState.orderForm.country,
                  value: country
              }
          },
          countryCode: countryCode,
          productPartnerCode: productPartnerCode,
          productPartnerName: productPartnerName,
          product: product,
          price: price,
          quantity: quantity,
          total: total,
          currency: currency
      }));

        // Use `userId` and `productId` as needed
    }
    componentDidUpdate(prevProps) {
      // Check if the country prop has changed
      if (this.props.location.state?.country !== prevProps.location.state?.country) {
          this.setState(prevState => ({
              orderForm: {
                  ...prevState.orderForm,
                  country: {
                      ...prevState.orderForm.country,
                      value: this.props.location.state?.country || ""
                  }
              }
          }));
      }
  }
    trackVisit() {
      const source = "purchase";
      const formData = new FormData();
      formData.append('event', source);
      axios.post("click", formData)
      .then(response => {
        // Handle the response if needed
        console.log('Visit tracked with parameter:', source, response.data);
    })
    .catch(error => {
        // Handle any errors here
        console.error('Error tracking visit', error);
    });
}
    orderHandler = ( event ) => {
        event.preventDefault();
        this.setState( { loading: true } );
        const formData = new FormData();
        for (let formElementIdentifier in this.state.orderForm) {
            formData.append(formElementIdentifier, this.state.orderForm[formElementIdentifier].value)
        }
        const { product, countryCode, productPartnerCode, productPartnerName, price, quantity, total, currency } = this.state;
        formData.append('product', product);
        formData.append('countryCode', countryCode);
        formData.append('productPartnerCode', productPartnerCode);
        formData.append('productPartnerName', productPartnerName);
        formData.append('price', price);
        formData.append('quantity', quantity);
        formData.append('total', total);
        formData.append('currency', currency);
        axios.post("orders/storeOrder", formData, {
            onUploadProgress: progressEvent => {
              this.setState({ loadingPercentage: Math.round(progressEvent.loaded / progressEvent.total * 100) });
            }
          })
            .then(response => {
              this.setState({
                loading: false
                
              });
              this.props.navigate('/thankyou');
            })
            .catch(error => {
              this.setState({ loading: false });
              let errorsStatus = { ...this.state.errors };
              if (error.response) {
                // Request made and server responded
    
                if (error.response.data.message.includes("Invalid Number")) {
                  console.log("invalid numberr");
                  errorsStatus.whatsappPhoneNumberError = true;
                }
                else {
                  console.log("not invalidd");
                  errorsStatus.sendingOrderError = true;
                }
              } else if (error.request) {
                // The request was made but no response was received
                errorsStatus.sendingOrderError = true;
                console.log("no response");
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                errorsStatus.networkError = true;
                console.log("request not set");
                console.log('Error', error.message);
              }
              this.setState({
                errors: errorsStatus
              });
            });
    }

    checkValidity(value, rules) {
        let isValid = true;

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        return isValid;
    }
    inputChangedHandler = (event, inputIdentifier) => {
        const updatedOrderForm = {
            ...this.state.orderForm
        };
        const updatedFormElement = { 
            ...updatedOrderForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid=this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched=true;
        updatedOrderForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedOrderForm) {
            formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({orderForm: updatedOrderForm, formIsValid: formIsValid});
    }
    // purchaseDoneHandler = () => {
    //     this.setState({ purchasing: false });
    //     this.props.navigate('/');
    //   }
      errorModalCancelHandler = () => {
        const updatedErrorsStatus = {
          ...this.state.errors
        };
        for (let key in this.state.errors) {
          updatedErrorsStatus[key] = false;
        }
        this.setState({ errors: updatedErrorsStatus });
      }
      

    render () {
        const formElementsArray = [];
        for (let key in this.state.orderForm) {
            formElementsArray.push({
                id: key,
                config: this.state.orderForm[key]
            });
        }
        let form = (
            <form onSubmit={this.orderHandler}>
                {formElementsArray.map(formElement => (
                    <Input 
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
                <Button btnType="Success" disabled = {!this.state.formIsValid || !this.state.product}>Commander</Button>
            </form>
        );
        if ( this.state.loading ) {
            form = <Spinner />;
        }
        return (
            <div>
        <Modal show={ this.state.errors.networkError ||  this.state.errors.sendingOrderError}
          modalClosed={this.errorModalCancelHandler}>

          <div>
            {this.state.errors.networkError ? <p style={{ color: 'red' }}>Erreur de réseau, veuillez réessayer <br />Si le problème persiste, prière de nous contacter</p> : <p></p>}
            {this.state.errors.sendingOrderError ? <p style={{ color: 'red' }}>Erreur dans la commande, veuillez réessayer <br />Si le problème persiste, prière de nous contacter</p> : <p></p>}
           
          </div>

          <div className={classes.ModalButton} >
            <Button btnType="Danger" clicked={this.errorModalCancelHandler}>OK</Button>
          </div>
        </Modal>

        {/* <Modal show={this.state.purchasing}
          modalClosed={this.purchaseDoneHandler} >

          <p className={classes.ModalTitleSuccess}>Commande effectuée ! <br /> </p>
          <p>Merci pour votre confiance <br />
            Vous allez recevoir un appel dans les plus brefs délais

          </p>
          <div className={classes.ModalButton} >
            <Button btnType="Success" clicked={this.purchaseDoneHandler}>OK</Button>
          </div>
        </Modal> */}
        

        <div className={classes.Form} >

          {form}

        </div>
      </div>
        );
    }
}

const PurchaseWrapper = () => {
    const location = useLocation();

    const navigate = useNavigate();

    return <Purchase location={location} navigate={navigate} />;
};
export default PurchaseWrapper;