import React from 'react';
import styles from './ProductInfo.module.css';

const ProductInfo = ({ title,texts }) => {
  return (
    <div className={styles.productInfo}>
      <h1>{title}</h1>
      {texts.map((text, index) => (
        <p key={index} className={styles.productInfoTexte}>
          {text}
        </p>
      ))}
    </div>
  );
};

export default ProductInfo;
