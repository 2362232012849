import React from 'react';
import PropTypes from 'prop-types';
import classes from '../../containers/ImageSlider/ImageSlider.module.css'
// const Card = ({property}) => {
//     const {index, picture, city} = property;
//     return (
//         <div id={`card-${index}`} className={classes.card}>
//             <img src={picture} alt={city} />

//         </div>
//     )
// }
const Card = ({ image, index }) => {
    return (
        <div id={`card-${index}`} className={classes.card}>
            <img src={image} alt={`Image ${index}`} />
        </div>
    );
}

Card.propTypes = {
    property: PropTypes.object.isRequired
}

export default Card;